@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comic+Sans+MS&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Times+New+Roman&display=swap');

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="roboto"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="roboto"]::before {
  content: 'Roboto';
  font-family: 'Roboto', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="comic-sans"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="comic-sans"]::before {
  content: 'Comic Sans';
  font-family: 'Comic Sans MS', cursive, sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="times-new-roman"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="times-new-roman"]::before {
  content: 'Times New Roman';
  font-family: 'Times New Roman', serif;
}

.ql-editor {
  line-height: 1.5 !important;
}

.ql-container .ql-editor {
  line-height: 1.5 !important;
}